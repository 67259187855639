import { get, longPost, post, put, upload } from "../request";
import * as api from "./index";

export const createChatSession = (userId: string) => {
  const form = new FormData();
  form.append("userId", userId);
  const url = `${api.createChatSession}`;
  return post(url, form);
};

export const getMsgInSession = (threadId: string) => {
  const form = new FormData();
  form.append("threadId", threadId);
  const url = `${api.msgInSession}`;
  return post(url, form);
};

export const sendMsg = (msg:string,threadId: string) => {
  const url = `${api.sendMsg}`;
  const raw = {
    message: msg,
    threadId: threadId,
  };
  return post(url, raw);
};

export const getChatSessions = (userId: string) => {
  const form = new FormData();
  form.append("userId", userId);
  const url = `${api.sessionsInUser}`;
  return post(url, form);
};

export type FeedType ='AI_ASSISTANT'|'AI_MONITOR'

export const feedChick = (msg:FeedType) => {
  const url = `${api.feedChick}`;
  const raw = {
    type: msg,
  };
  return post(url, raw);
};